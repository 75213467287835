import React, { Suspense, useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PrivateLayout, PublicLayout } from '../components/Layout';
import { useRedux } from '../hooks/reduxHook';
import { selectUser } from '../redux';
import routes, { RouteType } from './routes';

const AppRouter: React.FC = () => {
  const { select } = useRedux();
  const user = select(selectUser);

  const [nRoutes, Layout] = useMemo(() => {
    if (!user?.roles?.length) {
      return [routes.filter((r) => !r.isAuth), PublicLayout];
    }

    const routesUserRoles = routes.reduce((acc: RouteType[], r) => {
      if (user?.roles?.filter((el) => r?.permissions.includes(el)).length) {
        acc.push(r);
      }
      return acc;
    }, []);

    return [routesUserRoles, PrivateLayout];
  }, [user]);

  return (
    <Router>
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {nRoutes.map(({ name, isAuth, component: Component, ...rest }) => {
              return <Route key={name} {...rest} render={() => <Component />} />;
            })}
            <Redirect from="*" to="/" />
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
};

export default AppRouter;
