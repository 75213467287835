import { createApi } from '@reduxjs/toolkit/query/react';
import { Balance, ListResponseType, OtherUserType, UserDetailProps, UserNft } from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';
import { UsersSortFilterProps } from '../../pages/UsersInformation/reduce';
import { User } from '../auth/types.auth';

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Users', 'UserDetail', 'UserDetailAllInfo'],
  endpoints: (build) => ({
    listUsers: build.query<ListResponseType<OtherUserType>, UsersSortFilterProps>({
      query: ({ currentPage, selectValue, kycStatus, search, sortObject, itemsId }) => {
        const searchQuery = search ? `&search=${search}` : '';
        const kycStatusQuery = kycStatus ? `&kycStatus=${kycStatus.value}` : '';
        const itemsIdQuery = itemsId
          ? `&itemIdOwner=${itemsId.replaceAll(',', '%25').replace(/(%25)$/, '')}`
          : '';

        const sortObjectQuery =
          sortObject.sortField && sortObject.sortType
            ? `&${sortObject.sortField}=${sortObject.sortType}`
            : '';

        return {
          url: `${URLS.users}?page_number=${currentPage}&page_size=${selectValue.value}${searchQuery}${sortObjectQuery}${itemsIdQuery}${kycStatusQuery}`,
          method: 'get',
        };
      },
      providesTags: () => ['Users'],
    }),
    userDetail: build.query<User, UserDetailProps>({
      query: ({ id }) => ({
        url: URLS.userDetail.replace('<id>', id),
        method: 'get',
      }),
      providesTags: () => ['UserDetail', 'UserDetailAllInfo'],
    }),
    userDetailBalances: build.query<Balance[], UserDetailProps>({
      query: ({ id }) => ({
        url: URLS.userDetailBalances.replace('<id>', id),
        method: 'get',
      }),
      providesTags: () => ['UserDetail'],
    }),
    userDetailNft: build.query<UserNft[], UserDetailProps>({
      query: ({ id }) => ({
        url: URLS.userDetailNft.replace('<id>', id),
        method: 'get',
      }),
      providesTags: () => ['UserDetail'],
    }),
    banUser: build.mutation({
      query: ({ data }) => ({
        url: URLS.ban,
        method: 'post',
        data,
      }),
      invalidatesTags: () => ['UserDetailAllInfo'],
    }),
    unbanUser: build.mutation({
      query: ({ data }) => ({
        url: URLS.unban,
        method: 'post',
        data,
      }),
      invalidatesTags: () => ['UserDetailAllInfo'],
    }),
    editUser: build.mutation({
      query: ({ data }) => ({
        url: URLS.users,
        method: 'put',
        data,
      }),
      invalidatesTags: () => ['Users', 'UserDetail', 'UserDetailAllInfo'],
    }),
  }),
});

export const {
  useListUsersQuery,
  useBanUserMutation,
  useUnbanUserMutation,
  useUserDetailQuery,
  useUserDetailBalancesQuery,
  useUserDetailNftQuery,
} = usersAPI;
