import { PropsWithChildren } from 'react';
import useMetaTags from 'react-metatags-hook';
import { useLocation } from 'react-router-dom';
import { currentRoute } from '../../../helpers/routePosition';
import { useRedux } from '../../../hooks/reduxHook';
import { selectUser } from '../../../redux';
import routes, { RouteType } from '../../../router/routes';
import { Menu } from '../components';
import css from '../Layout.module.scss';

type PrivateLayoutProps = PropsWithChildren<{}>;

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { select } = useRedux();

  const user = select(selectUser);

  const routesUserRoles = routes.reduce((acc: RouteType[], r) => {
    if (user?.roles?.filter((el: string) => r?.permissions.includes(el)).length) {
      acc.push(r);
    }
    return acc;
  }, []);

  const currentPage = currentRoute(pathname, routesUserRoles);

  useMetaTags(
    {
      title: currentPage?.name,
      charset: 'utf8',
      lang: 'en',
    },
    [currentPage],
  );

  return (
    <div className={css.layoutWrapper}>
      <Menu className={css.menu} />
      <div className={css.content}>{children}</div>
    </div>
  );
};
