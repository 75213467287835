import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import s from './LogoWithText.module.scss';

export const LogoWithText = ({
  mode = 'white',
  className,
}: {
  mode?: 'white' | 'black';
  className?: string;
}) => (
  <div className={className}>
    <Link to="/">
      <img className={s.logo} src={Logo} alt="logo" />
    </Link>
  </div>
);
